<template>
    <!-- 
        Сторінка "Статистика кол-центру"
        - дозволяє переглянути список співробітників та інформацію по дзвінкам ( з пошуком по імені), та з фільтрацією по співробітнику та періоду
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title"/>

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <!-- кнопка відображення інфо-блоків -->
        <div class="hstack gap-2">
            <button 
                v-if="this.checks['001'] == true" 
                @click="infoblockShow = !infoblockShow" 
                type="button" 
                :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
            >
                <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
            </button>
        </div>
    </div>

    <!-- 
        Інформаційні блоки з підсумком по -
        - усього дзвінків
        - кількість вихідних дзвінків
        - кількість вхідних дзвінків
        - кількість пропущених дзвінків
    -->
    <infoblocks 
        v-if="infoblockShow" 
        :obj="this.information" 
        style="margin-top:15px;" 
    />
    
    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter"
    />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import infoblocks from '@/components/info/blocks' // інфо-блоки
import { Statistics } from '@/API' // клас для взаємодії з API 
import { storeS } from "@/store"; // глобальне сховище даних

let apiServe = new Statistics();

export default {
    components: {
        PageHeader,
        tablecustom,
        infoblocks
    },
    data(){
        return{
            title: this.$t('callcenterStatistics'), // Заголовок
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "statsByCallCenter"
            },
            objParams:{ // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                workerId: '',
                pr1: '',
                pr2: ''
            },
            infoblockShow: false, // прапорець відображення інфо-блоків
            rows: [], // Дані для відображення у таблиці
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: this.$t('Employee'),
                    text: "workerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('statOutgoing'),
                    text: "outgoingCallsCount",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('statIncoming'),
                    text: "incomingCallsCount",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('statMissed'),
                    text: "missingCallsCount",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('statRequest'),
                    text: "requestsCount",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('statDurationincoming'),
                    text: "durationIncoming",
                    align: "left",
                    status: true,
                    mutate: (item) => this.convertSecond(item)
                },
                {
                    name: this.$t('statOutgoingduration'),
                    text: "durationOutgoing",
                    align: "left",
                    status: true,
                    mutate: (item) => this.convertSecond(item)
                },
                {
                    name: this.$t('totalLengthconversations'),
                    text: "generalDuration",
                    align: "left",
                    status: true,
                    mutate: (item) => this.convertSecond(item)
                },
                {
                    name: this.$t('statRegistered'),
                    text: "registerCallsCount",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('statNotRegistered'),
                    text: "noRegisterCallsCount",
                    align: "left",
                    status: true,
                },
            ],
            rows: [], // Дані для відображення у таблиці
            information: [
                // Дані компонента, які відображають інформацію про дзвінки
                // Кожен елемент масиву містить:
                // * title - назву інформаційного блоку,
                // * value - кількість дзвінків з відповідним типом
                // * icon - клас іконки
                // * color - колір інформаційного блоку
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('statOutgoing'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info"
                },
                {
                    title: this.$t('statIncoming'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('statMissed'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger"
                }
            ],
        }
    },
    created(){
        this.objParams.pr1 = this.currentDate()
        this.objParams.pr2 = this.currentDate()
        this.getdata();
    },
    methods:{
        getdata(page){
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання статистики з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows (список співробітників) та objPages (інформація про сторінки).
            */

            this.objParams.page = page != undefined ? page : '1';
            apiServe.getAll(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items
                    this.objPages = result.data;

                    this.information[0].value = result.data.countOutgoing + result.data.countIncoming + result.data.countMissing;
                    this.information[1].value = result.data.countOutgoing;
                    this.information[2].value = result.data.countIncoming;
                    this.information[3].value = result.data.countMissing;
                } else {
                    this.$toast.error(this.$t('error') + ` #503`);
                }
            })
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за іменем співробітника
            */

            this.objParams.search = e
            this.getdata();
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            } 
        },
        convertSecond(second){
            /**
                * Конвертуємо кількість секунд в години та хвилини у вигляді рядка.
                * @param {number} second - Кількість секунд для конвертації.
                * @returns {string} - Рядок у форматі "години хвилини" або "хвилини", в залежності від вхідного значення.
            */

            var hours = Math.floor(second / 3600);
            var minutes = Math.floor((second % 3600) / 60);
            if(hours == 0) {
                var resultString = minutes + " хв." 
            } else {
                var resultString = hours + " год. " + minutes + " хв." 
            }
            return resultString
        },
        currentDate() {
            /**
                * Повертає поточну дату у форматі "рік-місяць-день".
                * @returns {string} - Рядок, що представляє поточну дату.
            */

            var date = new Date();
            var dateStr =
                date.getFullYear() + "-" + 
                ("00" + (date.getMonth()+1)).slice(-2) + "-" +
                ("00" + (date.getDate())).slice(-2);

            return dateStr
        }
    },
    computed: {
        checks() {
            /*
                Отримуємо дані налаштувань з глобального стору.
            */
            return storeS.checks
        },
    }
}
</script>